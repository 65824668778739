
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';

import GroupsModule from '@/store/settings/groups';
import GroupEntityModule from '@/store/settings/groups/entity';

@Component({
  components: {
    TitleReturn,
    FormBlock,
  },
})
export default class UserEntityAdd extends Form {
  get titlePage() {
    return GroupsModule.pageSettings.titleAdd;
  }

  returnBefore() {
    this.$router.push({ name: 'groups' });
  }

  async created() {
    await GroupEntityModule.initAdd();
  }
}
